<template>
    <div class="main">
        <div class="opts">
            <el-link
                type="info"
                href="https://github.com/netease-im/camellia/blob/master/docs/delay-queue/delay-queue.md"
            >
                netease-im 参考文档
            </el-link>
            <div>
                <el-link style="margin-right: 20px" type="info" @click="onReset">刷新</el-link>
            </div>
        </div>

        <h4>Topic信息列表</h4>
        <el-table :data="topicInfoList" stripe border style="width: 100%">
            <el-table-column type="expand" width="32">
                <template slot-scope="props">
                    <div class="topic_info">{{ props.row }}</div>
                </template>
            </el-table-column>
            <el-table-column label="Topic" prop="title" width="80" align="center" />
            <el-table-column label="等待" align="center" min-width="70">
                <template slot-scope="scope">
                    <span :class="scope.row.waitingQueueSize != 0 ? 'bold' : ''">{{ scope.row.waitingQueueSize }}</span>
                </template>
            </el-table-column>
            <el-table-column label="就绪" align="center" min-width="70">
                <template slot-scope="scope">
                    <span :class="scope.row.readyQueueSize != 0 ? 'bold' : ''">{{ scope.row.readyQueueSize }}</span>
                </template>
            </el-table-column>
            <el-table-column label="消费" align="center" min-width="70">
                <template slot-scope="scope">
                    <span :class="scope.row.ackQueueSize != 0 ? 'bold' : ''">{{ scope.row.ackQueueSize }}</span>
                </template>
            </el-table-column>
            <el-table-column
                v-for="item in topicInfoKey"
                :key="item.name"
                :label="item.name"
                align="center"
                min-width="70"
            >
                <template slot-scope="scope">
                    <span :class="scope.row.waitingQueueInfo[item.key] != 0 ? 'bold' : ''">
                        {{ scope.row.waitingQueueInfo[item.key] }}
                    </span>
                </template>
            </el-table-column>
        </el-table>

        <template v-if="monitorData">
            <h4>消费 [requestStatsList]</h4>
            <el-table :data="monitorData.requestStatsList" stripe border style="width: 100%">
                <el-table-column type="expand" width="32">
                    <template slot-scope="props">
                        <div class="topic_info">{{ props.row }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="Topic" prop="title" width="80" align="center" />

                <el-table-column label="send" prop="sendMsg" align="center" min-width="70" />
                <el-table-column label="pull" prop="pullMsg" align="center" min-width="70" />
                <el-table-column label="delete" prop="deleteMsg" align="center" min-width="70" />
                <el-table-column label="ack" prop="ackMsg" align="center" min-width="70" />
                <el-table-column label="get" prop="getMsg" align="center" min-width="70" />
                <el-table-column label="triggerReady" prop="triggerMsgReady" align="center" min-width="70" />
                <el-table-column label="triggerEndLife" prop="triggerMsgEndLife" align="center" min-width="70" />
                <el-table-column label="triggerTimeout" prop="triggerMsgTimeout" align="center" min-width="70" />
            </el-table>
            <h4>就绪 [readyQueueTimeGapStatsList]</h4>
            <el-table :data="monitorData.readyQueueTimeGapStatsList" stripe border style="width: 100%">
                <el-table-column type="expand" width="32">
                    <template slot-scope="props">
                        <div class="topic_info">{{ props.row }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="Topic" prop="title" width="80" align="center" />

                <el-table-column label="count" align="center" min-width="70">
                    <template slot-scope="scope">
                        <span :class="scope.row.count != 0 ? 'bold' : ''">
                            {{ scope.row.count }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="avgText" prop="avgText" align="center" min-width="70" />
                <el-table-column label="max" prop="max" align="center" min-width="70" />
            </el-table>
            <h4>发送 [pullMsgTimeGapStatsList]</h4>
            <el-table :data="monitorData.pullMsgTimeGapStatsList" stripe border style="width: 100%">
                <el-table-column type="expand" width="32">
                    <template slot-scope="props">
                        <div class="topic_info">{{ props.row }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="Topic" prop="title" width="80" align="center" />

                <el-table-column label="count" align="center" min-width="70">
                    <template slot-scope="scope">
                        <span :class="scope.row.count != 0 ? 'bold' : ''">
                            {{ scope.row.count }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="avgText" prop="avgText" align="center" min-width="70" />
                <el-table-column label="max" prop="max" align="center" min-width="70" />
            </el-table>
        </template>
    </div>
</template>
<script>
import { getMonitorData, getTopicInfoList } from '@/js/api/otherApi.js'
import { mapGetters } from 'vuex'

const _topicMap = {
    PROD_TOPIC_CP_FRIEND_TAG_MOD: '企微标签',
    PROD_TOPIC_CP_FRIEND: '企微加V',
    PROD_TOPIC_BENEFIT_ACTIVE: '带货激活',
    PROD_TOPIC_TRADE_NO_BELONG: '无归属订单',
    PROD_TOPIC_TRADE: '订单',
    PROD_TOPIC_TASK_JOIN_STEP: '新生接量',
    PROD_TOPIC_WORKFLOW: '工单',
    PROD_TOPIC_TALKFUN_LIVE: '欢拓直播',
    PROD_TOPIC_QUEUE_DOWNLOAD: '下载队列',
    PROD_TOPIC_HUNTER_OPPO_ASSIGN: '机会分配',
    PROD_TOPIC_HUNTER_OPPO_INCOMING: '机会生成',
}
export default {
    computed: {
        ...mapGetters(['userInfo']),
    },
    data() {
        return {
            monitorData: null,

            topicInfoList: [],
            topicInfoKey: [
                { name: '0-1m', key: 'sizeOf0To1min' },
                { name: '1-10m', key: 'sizeOf1minTo10min' },
                { name: '10-30m', key: 'sizeOf10minTo30min' },
                { name: '30m-1h', key: 'sizeOf30minTo1hour' },
                { name: '1-6h', key: 'sizeOf1hourTo6hour' },
                { name: '6h-1d', key: 'sizeOf6hourTo1day' },
                { name: '1-7d', key: 'sizeOf1dayTo7day' },
                { name: '7-30d', key: 'sizeOf7dayTo30day' },
                { name: '30d+', key: 'sizeOf30dayToInfinite' },
            ],
        }
    },
    watch: {
        userInfo: function () {
            this.onReset()
        },
    },
    created() {
        this.onReset()
    },
    methods: {
        onReset() {
            if (this.userInfo) {
                this.getMonitorDataApi()
                this.getTopicInfoListApi()
            } else {
                this.$router.push('/login?redirect=mqData')
            }
        },
        onShowDetail(item) {
            item.isShowDetail = !item.isShowDetail
        },

        getTopicInfoListApi() {
            getTopicInfoList().then((res) => {
                // 去重
                const _repetitionMap = {}
                const _list = []
                res.data.forEach((item) => {
                    if (!_repetitionMap[item.topic]) {
                        _repetitionMap[item.topic] = true
                        // 设置
                        item.title = _topicMap[item.topic]
                        if (!item.title) {
                            item.title = item.topic.split('_').pop()
                        }
                        _list.push(item)
                    }
                })
                // 排序
                _list.sort((a, b) => b.waitingQueueSize - a.waitingQueueSize)
                this.topicInfoList = _list
            })
        },
        getMonitorDataApi() {
            getMonitorData().then((res) => {
                Object.keys(res.data).forEach((k) => {
                    res.data[k].forEach((item) => {
                        item.title = _topicMap[item.topic]
                        if (!item.title) {
                            item.title = item.topic.split('_').pop()
                        }
                        item.avgText = parseInt((item.avg || 0) * 100) / 100
                    })
                })
                this.monitorData = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.main {
    padding: 16px;
}
.opts {
    display: flex;
    justify-content: space-between;
}

h4 {
    margin: 28px 0 12px;
}
.bold {
    font-weight: 900;
    color: red !important;
}
.topic_info {
    margin: 8px 20px;
}
.monitor {
    margin-bottom: 12px;
}
</style>
